import * as turf from '@turf/turf';

export const metersToPixelsAtMaxZoom = (meters, latitude) => (
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180)
);

export const getBounds = (positions) => {
  const polygons = turf.featureCollection(positions.map(
    (p) => turf.bboxPolygon(turf.bbox(turf.circle(
      [p.lon, p.lat],
      (p.radius + p.deadband) / 1000,
      { steps: 4, units: 'kilometers' },
    ))),
  ));
  return turf.bbox(turf.combine(polygons));
};
