import React from 'react';
import { useTheme } from '../contexts/theme';

const svgDir = require.context('!!@svgr/webpack!../../assets/svg', true, /\.svg$/);
// eslint-disable-next-line
const svgSrc = (path) => require(`../../assets/svg${path.replace('.', '')}`);

export default function Image({ i, src, ...p }) {
  const { image_dir } = useTheme();
  const file = `/${i}.svg`;
  const path = svgDir.keys().indexOf(image_dir + file) >= 0
    ? image_dir + file
    : `.${file}`;
  const Component = svgDir(path).default;
  const asSource = svgSrc(path).default;
  return src ? asSource : <Component {...p} />;
}
