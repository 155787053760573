import palette from './palette';
import common from './common';

// night theme
const styles = {
  ...common.styles,
  palette: {
    ...common.styles.palette,
    secondary: {
      main: palette.white,
      contrastText: palette.brand,
    },
    selected: {
      main: palette.yellow,
      contrastText: palette.brand,
    },
    mode: 'dark',
    background: {
      default: palette.brand,
      contrast: palette.white,
      unlockInstructions: 'transparent',
    },
    text: {
      unlockInstructions: palette.white,
    },
  },
  components: {
    ...common.styles.components,
    MuiBackdrop: {
      styleOverrides: {
        root: { background: `${palette.brand}` },
      },
    },
  },
};

const image_dir = './night';

const night = { styles, image_dir };

export default night;
