import React from 'react';
import logoDay from '../../assets/img/agami_logo_horiz_primary_mf.png';
import logoNight from '../../assets/img/agami_logo_horiz_accent_mf.png';
import { useTheme } from '../contexts/theme';

export default function Logo({ onClick, invert }) {
  const { theme } = useTheme();
  return (
    <div onClick={onClick} style={{ width: '200px', margin: '0 auto 0' }}>
      <img alt="Agami Route" src={theme === 'day' && !invert ? logoDay : logoNight} width="100%" />
    </div>
  );
}
