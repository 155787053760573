import palette from './palette';
import common from './common';

// day theme
const styles = {
  ...common.styles,
  palette: {
    ...common.styles.palette,
    secondary: {
      main: palette.brand,
      contrastText: palette.white,
    },
    selected: {
      main: palette.yellow,
      contrastText: palette.brand,
    },
    mode: 'light',
    background: {
      default: palette.white,
      contrast: palette.brand,
      unlockInstructions: `${palette.brand}96`,
    },
    text: {
      unlockInstructions: palette.white,
    },
  },
  components: {
    ...common.styles.components,
    MuiBackdrop: {
      styleOverrides: {
        root: { background: `${palette.white}` },
      },
    },
  },
};

const image_dir = './day';

const day = { styles, image_dir };

export default day;
