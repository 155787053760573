import React, { useState } from 'react';
import {
  Box, Button, Dialog, IconButton, Stack, Typography,
} from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import { CloseOutlined } from '@material-ui/icons';
import { useLanguage } from '../contexts/language';
import Layout from './Layout';

const LanguageSelector = () => {
  const { language, availableLanguages, setLanguage } = useLanguage();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <Box padding={1}>
      <IconButton
        onClick={() => setShowMenu((prev) => !prev)}
      >
        <LanguageIcon
          color="secondary"
          style={{ fontSize: '1.5rem' }}
        />
      </IconButton>
      <Dialog
        open={showMenu}
        bgcolor="red"
      >
        <Layout framed>

          <Stack
            justifyContent="center"
            alignItems="center"
            gap={1}
            bgcolor="transparent"
          >
            <IconButton
              onClick={() => setShowMenu(false)}
              style={{
                marginBottom: '2rem',
              }}
            >
              <CloseOutlined
                color="secondary"
                style={{ fontSize: '2rem' }}
              />
            </IconButton>

            <Typography
              variant="title"
              color="secondary"
              mb={2}
              borderBottom={1}
            >
              LANGUAGE
            </Typography>
            {availableLanguages?.map((lang) => (
              <Button
                key={lang}
                my={1}
                color={language === lang ? 'primary' : 'secondary'}
                variant={language === lang ? 'contained' : 'text'}
                style={{
                  textTransform: 'uppercase',
                }}
                onClick={() => {
                  setLanguage(lang);
                  setShowMenu(false);
                }}
              >
                {lang}
              </Button>
            ))}
          </Stack>
        </Layout>
      </Dialog>
    </Box>
  );
};

export default LanguageSelector;
