import String from './String';
import test_sound from '../../assets/audio/test_sound.mp3';
import SetupStep from './SetupStep';

export function SetupAudio(p) {
  return (
    <>
      <SetupStep
        pretitle={<String s="raise_volume" />}
        title={<String s="sound" />}
        image="phone"
        action={<String s="yes_continue" />}
        themeSwitcher
        stepNav
        {...p}
      />
      <audio src={test_sound} autoPlay loop style={{ display: 'none' }} />
    </>
  );
}
