const en = {
  welcome_to_agami: 'Welcome to Agami Route',
  login: 'SIGN IN',
  send_email: 'SEND EMAIL',
  login_with_password: 'Login with password',
  login_with_email: 'Login with email only',
  lang: 'English',
  select_lang: 'Choose your language',
  day: 'Day',
  night: 'Night',
  confirm: 'Confirm',
  continue: 'Continue',
  cancel: 'Cancel',
  refresh: 'Reload',
  yes_continue: 'Yes, continue',
  start: 'Start',
  back: 'Back',
  wait: 'Plase wait...',
  headset: 'Please use the provided headset for a better experience',
  raise_volume: "Turn up your device's volume",
  sound: 'Can you hear the sound correctly?',
  wait_location: 'Location accuracy check',
  gps: 'The app is trying to get your location',
  gps_error: "There's a problem with this phone GPS",
  gps_quality_bad: 'Location accuracy poor',
  gps_quality_bad_details: 'You may experience issues with automatic playback',
  gps_quality_average: 'Location accuracy acceptable',
  gps_quality_good: 'Location accuracy excellent',
  cookies: "There's a tour already in progress",
  cookies_action: 'Would you like to continue or to start it again?',
  cookies_delete: 'Change experience',
  cookies_maintain: 'Continue experience',
  unlocking:
    'If you need to use the app during the visit, unlock it using this code',
  // unlock: 'Unlock with an "L"',
  unlock: 'Confirm unlock?',
  unlock_subtitle: 'Unlocking the screen is for admins only',
  areyouready: 'Ready to start?',
  welcome: 'WELCOME',
  map: 'Map',
  code: 'Manual code',
  insert_code:
    "Insert a manual code and touch the 'Play' button",
  faq: [
    {
      q: 'Connection problems?',
      a: 'If you are experiencing connection problems try to...',
    },
    {
      q: 'Other FAQ',
      a: 'If you are experiencing connection problems try to...',
    },
  ],
  mobile_only: 'This App was designed for mobile devices.',
  screen_locked: 'Screen locked',
  swipe_left: 'Swipe left to unlock',
  admin_title: 'Admin controls',
  admin_close: 'Close',
  admin_test: 'Audio test',
  admin_reset_soft: 'Change experience',
  admin_reset_hard: 'App reset',
  admin_reset_hard_confirm: 'Do you want to reset the app?',
  admin_reset_hard_desc: 'App reset will erase all the saved experiences and their content. You\'ll need to download them again.',
  admin_logout: 'Logout',
  search_gps: 'Waiting for GPS coverage',
  select_experience: 'Select an experience',
  no_experience: 'Seems you don\'t have any available experience.',
  pause: 'Pause',
  stop: 'Stop',
  stop_all: 'Stop all',
  restart: 'Resume',
  beforestart:
    "Touch the 'Start' button when you are ready to begin the experience.",
  ispause: 'EXPERIENCE IS PAUSED',
  ispause_restart:
    "Click the 'Play' button to resume it.",
  play: 'Play',
  offline_experience: 'Offline experience',
  replay_title: 'Content is available for replay',
  replay_message: 'There is content available you already listened to. Do you want to replay it?',
  redeem_exp_title: 'Redeem experience',
  redeem_exp_result_title: 'Experience redeemed',
  redeem_exp_button: 'REDEEM',
  go_to_exp_button: 'START',
  check_email_login: 'Check your email to confirm login.',
  redeemed_until_days: 'You have access for the next',
  redeemed_until_date: 'You have access until',
  alreadly_redeemed: 'It seems you used this code before. You should already have this experience.',
  redeem_ok: 'The experience has been succesfully created for you.',

  gps_location_unavailable: 'Location services unavailable',
  gps_location_unavailable_desc: 'Location services are either disabled or permissions for access has not been granted. Please follow instructions on how to check correct settings.',
  gps_location_open_phone_settings: 'Open phone settings',
  gps_location_browser_in_use: 'the browser you are using',

  gps_location_android_1: 'Go to "Location"',
  gps_location_android_2: 'Check that "Use location" is enabled',
  gps_location_android_3: 'Open "App location permissions"',
  gps_location_android_4: 'Scroll to bottom in section "Not allowed", and look if',
  gps_location_android_5: 'is in there',
  gps_location_android_6: 'If that\'s the case, click over it and select either "Ask every time" or "Allow only while using the app"',

  gps_location_ios_1: 'Go to "Privacy & Security"',
  gps_location_ios_2: 'Check that "Location Services" is on',
  gps_location_ios_3: 'Go back to phone settings',
  gps_location_ios_4: 'Scroll down and search for the settings of',
  gps_location_ios_5: 'Inside settings, search for "Location"',
  gps_location_ios_6: 'Set it to either "Ask" or "Allow"',

  gps_location_chrome_1: 'Open Chrome',
  gps_location_chrome_2: 'Navigate to',
  gps_location_chrome_3: 'Open site settings by clicking the sliders icon just left of the website address',
  gps_location_chrome_4: 'Select "Permissions"',
  gps_location_chrome_5: 'Check that "Location" is allowed',

  gps_location_safari_1: 'Open Safari',
  gps_location_safari_2: 'Navigate to',
  gps_location_safari_3: 'Open site settings by clicking "Aa" icon just left of the website address',
  gps_location_safari_4: 'Select "Website settings"',
  gps_location_safari_5: 'Under "Allow" section, check that "Location" is set to either "Ask" or "Allow"',
  gps_location_safari_6: 'Click "Done"',

  gps_location_browser_1: 'Open your preferred browser',
  gps_location_browser_2: 'Navigate to',
  gps_location_browser_3: 'Open website settings',
  gps_location_browser_4: 'Check that location access permission is set to either "Ask" or "Allow"',

  go_without_gps: 'Proceed without location',
  go_without_gps_warning: 'You\'ll only be able to play content by manually entering codes.',

  read_instructions: 'Read the instructions',

  go_home: 'Home page',
};

export default en;
