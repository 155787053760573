import React, { useEffect } from 'react';

export function useNetwork() {
  const [available, setAvailable] = React.useState(navigator.onLine);

  useEffect(() => {
    const offlineSub = window.addEventListener('offline', () => {
      setAvailable(false);
    });

    const onlineSub = window.addEventListener('online', () => {
      setAvailable(true);
    });

    return () => {
      if (offlineSub) window.removeEventListener('offline', offlineSub);
      if (onlineSub) window.removeEventListener('offline', onlineSub);
    };
  }, []);

  return available;
}
