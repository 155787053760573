import React from 'react';
import {
  FormGroup,
  Grid,
  Typography,
  Switch,
  IconButton,
} from '@material-ui/core';
import DayIcon from '@material-ui/icons/WbSunny';
import NightIcon from '@material-ui/icons/Nightlight';
import { useTheme } from '../contexts/theme';
import String from './String';
import palette from '../../config/themes/palette';

export default function ThemeSwitcher({ darkBackground }) {
  const { theme, toggleTheme } = useTheme();
  const labelColors = darkBackground || theme === 'night' ? {
    selected: palette.yellow,
    primary: palette.white,
  } : {
    selected: palette.brand,
    primary: palette.dark_grey,
  };

  return (
    <FormGroup>
      <Grid container alignItems="center" width="100%">
        <Grid item xs={3} align="center">
          <Typography color={theme === 'day' ? labelColors.selected : labelColors.primary}>
            <String s="day" />
          </Typography>
        </Grid>
        <Grid item xs={6} align="center">
          <Switch
            icon={<SwitchIcon theme={theme} />}
            checkedIcon={<SwitchIcon theme={theme} />}
            checked={theme === 'night'}
            onChange={toggleTheme}
            color="primary"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography color={theme === 'night' ? labelColors.selected : labelColors.primary}>
            <String s="night" />
          </Typography>
        </Grid>
      </Grid>
    </FormGroup>
  );
}

function SwitchIcon({ theme }) {
  return (
    <IconButton
      size="small"
      style={{
        background: theme === 'day' ? palette.light_grey : palette.dark_grey,
        transform: 'translate(0, -2px)',
      }}
    >
      {theme === 'day' ? (
        <DayIcon style={{ fontSize: '15px' }} />
      ) : (
        <NightIcon style={{ fontSize: '15px' }} />
      )}
    </IconButton>
  );
}
