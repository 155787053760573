import React from 'react';
import { Box, Button } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { PiMusicNotes, PiMusicNote } from 'react-icons/pi';
import { motion } from 'framer-motion';
import PlayerBottomSheet from './PlayerBottomSheet';
import { useTheme } from '../contexts/theme';
import { useExperience } from '../contexts/experience';

const AnimatedSpan = ({ startAnimation, children }) => {
  if (!startAnimation) {
    return (
      <span
        style={{
          display: 'inline-flex',
        }}
      >
        {children}
      </span>
    );
  }

  return (
    <motion.span
      style={{
        display: 'inline-flex',
      }}
      animate={{
        scale: [1, 1.3, 1, 1.3, 1],
        rotate: [0, 360, 360, 360, 0],
      }}
      transition={{
        repeat: Infinity,
        duration: 3,
        ease: 'backInOut',
      }}
    >
      {children}
    </motion.span>
  );
};

const PlayerOnMap = () => {
  const [openSheet, setOpenSheet] = React.useState(false);
  const { styles } = useTheme();
  const primary = styles.palette.primary.main;
  const { isActive, active } = useExperience();
  const singleSpotActive = active?.length === 1;
  const someIsPlaying = active?.some((spot) => spot.audio.playing());
  const isManualActive = singleSpotActive && Boolean(active[0]?.spot?.code);

  React.useEffect(() => {
    if (!isActive) {
      setOpenSheet(false);
    }

    if (isActive && isManualActive) {
      setOpenSheet(true);
    }
  }, [isActive, isManualActive]);

  return (
    <>
      <PlayerBottomSheet
        openSheet={openSheet}
        setOpenSheet={setOpenSheet}
      />
      <Box
        position="absolute"
        width="100%"
        bgcolor={primary}
        bottom={0}
        zIndex={999}
        style={{
          opacity: isActive ? 1 : 0,
          pointerEvents: isActive ? 'all' : 'none',
          transform: isActive
            ? 'scale(1) translate(0px, 0px)'
            : 'scale(0.8) translate(0px, 100px)',
          transition: 'all .5s ease-in-out',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => setOpenSheet(true)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 4,
            }}
          >
            <AnimatedSpan startAnimation={someIsPlaying}>
              {singleSpotActive
                ? <PiMusicNote style={{ fontSize: '1.5rem' }} />
                : <PiMusicNotes style={{ fontSize: '1.5rem' }} />}
            </AnimatedSpan>

            {singleSpotActive ? (`${active[0].spot.label}`) : 'multiple agami active'}
          </span>

          {someIsPlaying
            ? <PauseIcon style={{ fontSize: '2rem' }} />
            : <PlayIcon style={{ fontSize: '2rem' }} />}
        </Button>
      </Box>
    </>
  );
};

export default PlayerOnMap;
