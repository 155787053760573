import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import decoration from '../../assets/svg/homepage-decor-top.svg';
import Layout from './Layout';

const decorStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  opacity: '.25',
};

const Loading = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots === '.......' ? '' : `${prevDots}.`));
    }, 250);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Layout fullscreen>
      <img
        style={decorStyle}
        src={decoration}
        alt="decoration"
      />
      <Typography variant="pretitle" color="white">
        LOADING
        {dots}
      </Typography>
    </Layout>
  );
};

export default Loading;
