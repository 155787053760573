import React, { useEffect, useState } from 'react';
import {
  Box, Dialog, Typography, Button, CircularProgress, Stack,
} from '@material-ui/core';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import Layout from './Layout';
import Logo from './Logo';
import String from './String';
import test_sound from '../../assets/audio/test_sound.mp3';
import { useSession } from '../contexts/session';
import { AUDIO_PRECACHE_NAME, MAPBOX_PRECACHE_NAME, TILES_PRECACHE_NAME } from '../../config/precache';

export default function AdminControls({ show, onClose }) {
  const { resetSavedExperiences, logout } = useSession();

  const [test, setTest] = useState(false);
  const [hardResetState, setHardResetState] = useState(null);

  const softReset = () => {
    window.location = '/';
  };

  const hardReset = async () => {
    if (!hardResetState) {
      setHardResetState('awaitingConfirm');
      return;
    }
    if (hardResetState === 'awaitingConfirm') {
      setHardResetState('resetting');
      resetSavedExperiences();
      await caches.delete(AUDIO_PRECACHE_NAME);
      await caches.delete(TILES_PRECACHE_NAME);
      await caches.delete(MAPBOX_PRECACHE_NAME);
      window.location = '/';
    }
  };

  const logoutUser = async () => {
    await logout();
    window.location = '/';
  };

  useEffect(() => {
    setTest(false);
  }, [show]);

  return (
    <Dialog fullScreen open={show}>
      <Layout
        framed
        top={(
          <Box mt="4vh" textAlign="center">
            <Logo />
          </Box>
        )}
      >
        <Stack alignItems="center" spacing={1} position="relative" width="90%" maxWidth="600px">
          <Typography variant="title" mb={2}>
            <String s="admin_title" />
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={() => setTest((s) => !s)}
          >
            <String s="admin_test" />
            {test && <MusicNoteIcon style={{ marginLeft: 8 }} />}
          </Button>
          <Button color="secondary" variant="contained" fullWidth onClick={softReset}>
            <String s="admin_reset_soft" />
          </Button>
          <Button color="secondary" variant="contained" fullWidth onClick={hardReset}>
            <String s="admin_reset_hard" />
          </Button>
          <Button color="secondary" variant="contained" fullWidth onClick={logoutUser}>
            <String s="admin_logout" />
          </Button>
          <Stack width="100%" pt={3}>
            <Button color="secondary" variant="outlined" fullWidth onClick={onClose}>
              <String s="admin_close" />
            </Button>
          </Stack>
        </Stack>
      </Layout>
      {test && <audio src={test_sound} autoPlay loop style={{ display: 'none' }} />}
      <Dialog
        fullScreen
        open={!!hardResetState}
        onClose={() => setHardResetState(null)}
      >
        <Layout framed>
          <Stack spacing={3} position="relative" width="90%" maxWidth="600px">
            <Typography variant="title">
              <String s="admin_reset_hard_confirm" />
            </Typography>
            <Typography>
              <String s="admin_reset_hard_desc" />
            </Typography>
            <Stack direction="row" width="100%" spacing={2}>
              <Button variant="outlined" color="secondary" onClick={() => setHardResetState(null)} autoFocus>
                <String s="cancel" />
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={hardReset}
                endIcon={hardResetState === 'resetting' && <CircularProgress style={{ marginLeft: 8, color: 'white' }} size="20px" />}
              >
                <String s="confirm" />
              </Button>
            </Stack>
          </Stack>
        </Layout>
      </Dialog>
    </Dialog>
  );
}
