import React, { useMemo, useState } from 'react';
import languages, { default_language } from '../../config/languages';

import alarm_en from '../../assets/audio/en/lock_alert.mp3';

const ALARM_SOUNDS = {
  en: alarm_en,
};

const LanguageContext = React.createContext(null);

export default function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(default_language);

  const availableLanguages = Object.keys(languages);
  const content = (key, l) => languages[l || language][key];
  const alarmSound = ALARM_SOUNDS[language];

  const contextValue = useMemo(() => ({
    language, setLanguage, availableLanguages, content, alarmSound,
  }), [language, setLanguage, availableLanguages, content, alarmSound]);

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => React.useContext(LanguageContext);
