const palette = {
  brand: '#21323D',
  white: '#FFFFFF',
  black: '#000000',
  red: '#940000',
  green: '#32fd43',
  yellow: '#FAE632',
  dark_yellow: '#B7A504',
  grey: '#A5A093',
  light_grey: '#F2EFEB',
  dark_grey: '#6a6f7a',
};

export default palette;
