import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Backdrop,
  Tabs,
  Tab,
} from '@material-ui/core';
import NoSleep from 'nosleep.js';
import Layout from '../components/Layout';
import String from '../components/String';
import ThemeSwitcher from '../components/ThemeSwitcher';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Map from '../components/Map';
import { useLanguage } from '../contexts/language';
import { useExperience } from '../contexts/experience';
import useVisibility from '../hooks/useVisibility';
import ManualCode from '../components/ManualCode';
import Replay from '../components/Replay';
import PlayerOnMap from '../components/PlayerOnMap';

const noSleep = new NoSleep();

export default function Experience() {
  const { alarmSound } = useLanguage();
  const isVisible = useVisibility();
  const {
    positions, start, started, isActive, hasManualSpots, hasGeoSpots, canReplay,
  } = useExperience();

  const [tab, setTab] = useState(!hasGeoSpots && hasManualSpots ? 'code' : 'map');

  const handleStart = () => {
    noSleep.enable();
    start();
  };

  useEffect(() => {
    // prevent pull-down-to-refresh
    document.body.classList.add('no-pull-refresh');

    return () => {
      document.body.classList.remove('no-pull-refresh');
      noSleep.disable();
    };
  }, []);

  return (
    <Layout
      fullscreen
      top={<Header />}
      bottom={(
        <Footer>
          <ThemeSwitcher darkBackground />
          {/* {started && <Lock />} */}
          {/* {started && <Pause />} */}
        </Footer>
      )}
    >
      {hasManualSpots && hasGeoSpots && (
        <Tabs value={tab} onChange={(e, value) => setTab(value)} aria-label="tabs" style={{ width: '100%', display: 'flex' }}>
          <Tab label="Map" value="map" style={{ flex: 1, maxWidth: 'unset' }} disabled={!started || isActive} />
          <Tab label="Manual code" value="code" style={{ flex: 1, maxWidth: 'unset' }} disabled={!started || isActive} />
        </Tabs>
      )}
      <Layout>
        {hasGeoSpots && <Map positions={positions} userLocation />}
        {hasManualSpots && <ManualCodeOverlay open={tab === 'code'} />}
        <PlayerOnMap />
        <ReplayOverlay open={canReplay} />
        <StartOverlay open={!started} onStart={handleStart} />
      </Layout>
      {started && !isVisible && (
        <audio src={alarmSound} autoPlay loop style={{ display: 'none' }} />
      )}
    </Layout>
  );
}

function ManualCodeOverlay({ open }) {
  return (
    <Backdrop style={{ position: 'absolute', zIndex: 5 }} open={open}>
      <Layout justify="center">
        <ManualCode />
      </Layout>
    </Backdrop>
  );
}

function ReplayOverlay({ open }) {
  return (
    <Backdrop style={{ position: 'absolute', zIndex: 10 }} open={open}>
      <Layout justify="center">
        {open && <Replay />}
      </Layout>
    </Backdrop>
  );
}

function StartOverlay({ open, onStart }) {
  return (
    <Backdrop style={{ position: 'absolute', zIndex: 10 }} open={open}>
      <Layout justify="center">
        <Box textAlign="center" ml={4} mr={4}>
          <Typography variant="title" mb={2}>
            <String s="areyouready" />
          </Typography>
          <Typography variant="text" mb={3}>
            <String s="beforestart" />
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={onStart}
          >
            <String s="start" />
          </Button>
        </Box>
      </Layout>
    </Backdrop>
  );
}
