import String from './String';
import SetupStep from './SetupStep';

export function SetupHeadphones(p) {
  return (
    <SetupStep
      title={<String s="headset" />}
      image="headphones"
      action={<String s="continue" />}
      themeSwitcher
      stepNav
      {...p}
    />
  );
}
