import React, {
  useState, useEffect, useCallback, useMemo, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Loading from '../components/Loading';
import {
  getGeoXpConfig, getSupabaseSession, getUserExperiences, getUserProfile, signOut,
} from '../utils/supabase';
import Login from '../views/Login';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { AGAMI_GLOBAL_EXPERIENCE_ID, EXPERIENCES_STORAGE_KEY } from '../../config/config';
import { validateConfig } from '../utils/geoXp';
import useToast from '../hooks/useToast';
import { useNetwork } from '../hooks/useNetwork';

const SessionContext = React.createContext(null);

const sortById = (a, b) => {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};

export default function SessionProvider({ children }) {
  const mounted = useRef(false);

  const location = useLocation();

  const [user, setUser] = useState(null);
  const [offline, setOffline] = useState(null);
  const [loading, setLoading] = useState(true);
  const [experiences, setExperiences] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [savedExperiences, setSavedExperiences] = useLocalStorage(EXPERIENCES_STORAGE_KEY, []);

  const toast = useToast();
  const networkAvailable = useNetwork();

  const getSession = useCallback(async () => {
    if (!networkAvailable) return;
    const { data: session } = await getSupabaseSession();
    if (!session) {
      setUser(undefined);
      setLoading(false);
      return;
    }

    const { data: profile } = await getUserProfile();
    if (!profile) {
      setUser(undefined);
      Sentry.setUser(null);
      setLoading(false);
      return;
    }

    // we can only set ID here for privacy compliances
    Sentry.setUser({
      id: session.user?.id,
    });

    setUser({
      id: session.user?.id,
      completeName: profile.name ? `${profile?.name || ''} ${profile?.surname || ''}` : undefined,
      email: session.user?.email,
      confirmedAt: session.user?.confirmed_at,
      role: profile.roles?.role_name,
      ...profile,
    });

    const { data: experiencesData } = await getUserExperiences({ userId: session.user?.id });
    if (!experiencesData) {
      setExperiences(savedExperiences.map((e) => ({ ...e, fromStorage: true })));
      return;
    }
    // excludes agami global and user default library from experiences list
    setExperiences(experiencesData.filter((e) => e.experience_id !== AGAMI_GLOBAL_EXPERIENCE_ID
      && !e.experiences?.is_default).map((e) => {
      const alreadySaved = savedExperiences.find((s) => s.id === e.experience_id);
      if (alreadySaved) {
        return {
          ...alreadySaved,
          fromStorage: true,
        };
      }
      return {
        id: e.experience_id,
        label: e.experiences.name,
        offlineEnabled: !e.access_code_id,
      };
    }).sort(sortById));

    setLoading(false);
  }, [savedExperiences]);

  const offlineMode = useCallback((type) => {
    setOffline(type && typeof type === 'string' ? type : 'forced');
    setExperiences(savedExperiences.map((e) => ({ ...e, fromStorage: true })));
    setLoading(false);
  }, [savedExperiences, networkAvailable]);

  const logout = useCallback(async () => {
    await signOut();
  }, []);

  const resetSavedExperiences = useCallback(() => {
    setSavedExperiences([]);
  }, []);

  const getExperienceConfig = useCallback(async (experience) => {
    const { data, error } = await getGeoXpConfig({ experienceId: experience.id });
    if (error) {
      console.error(error);
      toast.error('Error getting experience configuration');
      return;
    }
    if (!data) return;

    const isValidConfig = validateConfig(data);
    if (!isValidConfig) {
      console.error('Invalid geoXp config options - cannot load');
      toast.error('Experience configuration is invalid, cannot load');
      return;
    }

    return { ...experience, config: data };
  }, []);

  const selectExperience = useCallback(async (experience) => {
    if (!experience.config) {
      const experienceWithConfig = await getExperienceConfig(experience);
      setSelectedExperience(experienceWithConfig);
      return experienceWithConfig;
    }
    setSelectedExperience(experience);
    return experience;
  }, []);

  const saveExperience = useCallback(async (experience) => {
    // eslint-disable-next-line
    if (experience.fromStorage) delete experience.fromStorage;
    const toSave = await getExperienceConfig(experience);
    if (!toSave || !toSave.config) return;
    const updateExperiences = (toUpdate) => {
      const filtered = toUpdate.filter((e) => e.id !== toSave.id);
      return [...filtered, toSave].sort(sortById);
    };
    setSavedExperiences(updateExperiences);
    setExperiences(updateExperiences);

    return toSave;
  }, [savedExperiences]);

  useEffect(() => {
    if (networkAvailable) getSession();
    else offlineMode('forced');
  }, []);

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    else {
      if (!offline && !networkAvailable) offlineMode('no-network');
      if (offline !== 'forced' && networkAvailable) setOffline(null);
    }
  }, [networkAvailable, offline]);

  const contextValue = useMemo(() => ({
    user,
    experiences,
    loading,
    selectedExperience,
    offline,
    saveExperience,
    selectExperience,
    logout,
    resetSavedExperiences,
  }), [
    user,
    experiences,
    loading,
    selectedExperience,
    offline,
    saveExperience,
    selectExperience,
    logout,
    resetSavedExperiences,
  ]);

  if (loading) return <Loading />;

  if (
    !user
    && offline !== 'forced'
    && location.pathname !== '/redeem-experience'
    && location.pathname !== '/redeem-experience-anonymous'
  ) return <Login success={getSession} offlineMode={offlineMode} />;

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
}

export const useSession = () => React.useContext(SessionContext);
