import React, { useCallback, useState } from 'react';
import { PiEyeBold, PiEyeSlashBold } from 'react-icons/pi';
import {
  Box, Button, CircularProgress, FormControl, IconButton, Stack, TextField,
} from '@material-ui/core';
import { signInWithEmail, signInWithEmailAndPassword } from '../utils/supabase';
import useToast from '../hooks/useToast';
import Logo from '../components/Logo';
import String from '../components/String';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SetupStep from '../components/SetupStep';
import AppVersion from '../components/AppVersion';
import { HOSTNAME } from '../../config/config';
import LanguageSelector from '../components/LanguageSelector';

const Login = ({ success, offlineMode }) => {
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const login = useCallback(async () => {
    if (!email || !password) return;

    setLoginLoading(true);

    const { error } = await signInWithEmailAndPassword({ email, password });
    if (error) {
      toast.error('Wrong credentials');
      console.error(error);
    } else {
      success();
    }

    setLoginLoading(false);
  }, [email, password]);

  const getOtp = useCallback(async () => {
    if (!email) return;

    setOtpLoading(true);

    const { error } = await signInWithEmail({ email, redirectTo: HOSTNAME });
    if (error) {
      toast.error('Invalid email');
      console.error(error);
    } else {
      toast.success('Instructions have been sent. Check your email');
    }

    setOtpLoading(false);
  }, [email, password]);

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      login();
    }
  };

  const loading = loginLoading || otpLoading;

  return (
    <Layout
      fullscreen
      framed
      top={(
        <>
          <Box
            position="absolute"
            top={0}
            right={0}
            textAlign="right"
          >
            <LanguageSelector />
          </Box>
          <Box mt="8vh" textAlign="center">
            <Logo />
          </Box>
        </>
      )}
      bottom={(
        <Footer>
          <AppVersion />
        </Footer>
      )}
    >
      <SetupStep
        form={(
          <Stack spacing={1} mb={2} width="100%">
            <FormControl variant="outlined" fullWidth>
              <TextField
                value={email || ''}
                onChange={(e) => setEmail(e.currentTarget.value)}
                type="email"
                label="Email address"
                required
                onKeyUp={handleKeyUp}
              />
            </FormControl>
            {!isOtp && (
              <Box style={{ position: 'relative' }}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={password || ''}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    required
                    onKeyUp={handleKeyUp}
                  />
                </FormControl>
                <IconButton
                  style={{
                    position: 'absolute', top: '50%', right: '10px', transform: 'translate(0, -50%)',
                  }}
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <PiEyeSlashBold /> : <PiEyeBold />}
                </IconButton>
              </Box>
            )}
            <Stack pt={3} spacing={3}>
              <Stack spacing={0}>
                {!isOtp ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={login}
                    disabled={!email || !password || loading}
                    endIcon={loginLoading ? <CircularProgress style={{ marginLeft: 8, color: 'white' }} size="20px" /> : null}
                    fullWidth
                  >
                    <String s="login" />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={getOtp}
                    disabled={!email || loading}
                    endIcon={otpLoading ? <CircularProgress style={{ marginLeft: 8, color: 'white' }} size="20px" /> : null}
                    fullWidth
                  >
                    <String s="send_email" />
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="ghost"
                  onClick={() => setIsOtp((s) => !s)}
                  disabled={loading}
                  fullWidth
                >
                  {isOtp ? <String s="login_with_password" /> : <String s="login_with_email" />}
                </Button>
              </Stack>

              <Button
                color="secondary"
                variant="outlined"
                onClick={offlineMode}
                fullWidth
              >
                <String s="offline_experience" />
              </Button>
            </Stack>
          </Stack>
        )}
        themeSwitcher
      />
    </Layout>
  );
};

export default Login;
