import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const transitionProps = {
  classNames: 'fade',
  timeout: {
    enter: 300,
    exit: 300,
  },
};

const viewContainer = { height: '100vh', width: '100%', position: 'relative' };

const viewElement = {
  position: 'absolute',
  width: '100%',
  height: '100%',
};

export function ViewTransition({ _key, children }) {
  const ref = React.createRef();
  return (
    <TransitionGroup style={viewContainer}>
      <CSSTransition key={_key} nodeRef={ref} {...transitionProps}>
        <div ref={ref} style={viewElement}>
          {children}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export function Transition({ _key, children, on }) {
  const ref = React.createRef();
  const clonedChildren = React.cloneElement(children, { ref });
  return (
    <CSSTransition
      key={_key}
      in={on}
      nodeRef={ref}
      unmountOnExit
      {...transitionProps}
    >
      {clonedChildren}
    </CSSTransition>
  );
}
