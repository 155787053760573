import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowRightAlt';
import { Link } from 'react-router-dom';
import useToast from '../hooks/useToast';
import Logo from '../components/Logo';
import String from '../components/String';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SetupStep from '../components/SetupStep';
import AppVersion from '../components/AppVersion';
import { useQueryParams } from '../hooks/useQueryParams';
import { useSession } from '../contexts/session';
import { redeemExpCode } from '../utils/supabase';
import { HOSTNAME } from '../../config/config';
import { addDays, daysFromNow } from '../utils/utils';

const RedeemExp = () => {
  const toast = useToast();
  const queryParams = useQueryParams();
  const codeFromUrl = queryParams.get('code') ?? '';
  const decodedCodeFromUrl = decodeURIComponent(codeFromUrl);
  const expName = queryParams.get('expName');

  const decodedExpName = expName
    ? decodeURIComponent(expName)
    : '';

  const { user } = useSession();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(decodedCodeFromUrl);
  const [redeemLoading, setRedeemLoading] = useState(false);

  const [result, setResult] = useState(null);
  /*
    {
      alreadyRedeemed: boolean
      accessCode: {
        access_expires_after: number
      }
      userExperience: {
        created_at: Date
      }
    }
  */

  const redeem = useCallback(async () => {
    if (!email || !code) return;

    setRedeemLoading(true);

    const { data, error } = await redeemExpCode({ email, code, redirectTo: HOSTNAME });
    if (error) {
      toast.error(error.toString());
      console.error(error);
    } else if (data) {
      setResult(data);
    } else {
      toast.error('Something went wrong.');
      console.error('Something went wrong. Missing response.');
    }

    setRedeemLoading(false);
  }, [email, code]);

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') redeem();
  };

  useEffect(() => {
    if (user?.email) {
      setEmail(user?.email);
    }
  }, [user?.email]);

  // checks expiration date. If number of days greater than 31, use date instead
  const redeemedUntil = result?.userExperience?.created_at
    && result?.accessCode?.access_expires_after
    && daysFromNow(addDays(
      result.userExperience.created_at,
      result.accessCode?.access_expires_after,
    )) > 0;

  const redeemedUntilDays = redeemedUntil
    ? daysFromNow(addDays(
      result.userExperience.created_at,
      result.accessCode?.access_expires_after,
    ))
    : null;

  const redeemedUntilDate = redeemedUntil
    ? addDays(
      result.userExperience.created_at,
      result.accessCode?.access_expires_after,
    ).toDateString()
    : null;

  return (
    <Layout
      fullscreen
      framed
      top={(
        <Box mt="8vh" textAlign="center">
          <Logo />
        </Box>
      )}
      bottom={(
        <Footer>
          <AppVersion />
        </Footer>
      )}
    >
      <SetupStep
        themeSwitcher
        form={!result ? (
          <Stack
            spacing={3}
            mb={2}
            width="100%"
          >
            <Typography
              variant="title"
              fontWeight="bold"
              mb={2}
            >
              <String s="redeem_exp_title" />
              {' '}
              <em>{decodedExpName}</em>
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <TextField
                value={email || ''}
                onChange={(e) => setEmail(e.currentTarget.value)}
                type="email"
                label="Email address"
                required
                onKeyUp={handleKeyUp}
                disabled={redeemLoading || !!user?.email}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <TextField
                value={code || ''}
                onChange={(e) => setCode(e.currentTarget.value)}
                type="text"
                label="Code"
                required
                onKeyUp={handleKeyUp}
                disabled={redeemLoading}
              />
            </FormControl>
            <Button
              color="primary"
              variant="contained"
              onClick={redeem}
              disabled={!email || !code || redeemLoading}
              fullWidth
              endIcon={redeemLoading ? <CircularProgress color="secondary" style={{ marginLeft: 8 }} size="20px" /> : null}
            >
              <String s="redeem_exp_button" />
            </Button>

            <Button
              color="secondary"
              variant="text"
              startIcon={<BackIcon style={{ transform: 'rotate(-180deg)' }} />}
              component={Link}
              to="/"
            >
              <String s="go_home" />
            </Button>

          </Stack>
        ) : (
          <Stack
            spacing={6}
            mb={2}
            width="100%"
          >
            <Typography
              variant="title"
              fontWeight="bold"
              mb={2}
            >
              <String s="redeem_exp_result_title" />
              {' '}
              <em>{decodedExpName}</em>
            </Typography>
            <Stack
              spacing={0}
              width="100%"
            >
              {result.alreadyRedeemed ? (
                <Typography>
                  <String s="alreadly_redeemed" />
                </Typography>
              ) : (
                <Typography>
                  <String s="redeem_ok" />
                </Typography>
              )}

              {redeemedUntil && redeemedUntilDays <= 31 && (
                <Typography>
                  <String s="redeemed_until_days" />
                  {' '}
                  <strong>
                    {redeemedUntilDays}
                    {' '}
                    days.
                  </strong>
                </Typography>
              )}

              {redeemedUntil && redeemedUntilDays > 31 && (
                <Typography>
                  <String s="redeemed_until_date" />
                  {' '}
                  <strong>{redeemedUntilDate}</strong>
                  .
                </Typography>
              )}
            </Stack>

            {!user ? (
              <Typography variant="pretitle">
                <String s="check_email_login" />
              </Typography>
            ) : (
              <Button
                href="/"
                color="primary"
                variant="contained"
                fullWidth
              >
                <String s="go_to_exp_button" />
              </Button>
            )}
          </Stack>
        )}
      />
    </Layout>
  );
};

export default RedeemExp;
