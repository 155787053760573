import palette from './palette';

const styles = {
  typography: {
    fontFamily: 'Messina Sans',
    title: {
      fontFamily: 'Neusa Next Pro',
      fontWeight: 'normal',
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    pretitle: {
      fontFamily: 'Messina Sans',
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    text: {
      fontFamily: 'Messina Sans',
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: palette.yellow,
      contrastText: palette.brand,
    },
    secondary: {
      main: palette.brand,
      contrastText: palette.white,
    },
    selected: {
      main: palette.brand,
      contrastText: palette.yellow,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: 'h2',
          pretitle: 'h5',
          subtitle: 'h4',
          text: 'p',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: { textAlign: 'left', borderRadius: '8px' },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: { fontSize: '1.5rem' },
        input: { width: '100px' },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: '1rem',
          padding: '.75rem 4rem',
          borderRadius: '8px',
          ...(ownerState.variant === 'contained' && { border: '1px solid transparent' }),
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 'bold',
          padding: '0.75rem 0px',
          color: palette.white,
          '&.Mui-disabled': {
            color: palette.grey,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '& +.MuiSwitch-track': {
            backgroundColor: palette.dark_grey,
          },
          '&.Mui-checked': {
            '& +.MuiSwitch-track': {
              backgroundColor: palette.dark_grey,
            },
          },
        },
      },
    },
  },
  shadows: Array.from(Array(25).keys()).map(() => 'none'),
};

const common = { styles };

export default common;
