import React, { useMemo, useState } from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import themes from '../../config/themes';

const ThemeContext = React.createContext(null);

export default function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('day');

  const toggleTheme = () => setTheme(theme === 'day' ? 'night' : 'day');

  const { styles, image_dir } = useMemo(() => themes[theme], [theme]);

  const muiTheme = useMemo(() => createTheme(styles), [styles]);

  const contextValue = useMemo(() => ({
    theme, styles, image_dir, toggleTheme,
  }), [theme, styles, image_dir, toggleTheme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => React.useContext(ThemeContext);
