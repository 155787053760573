import React, { useEffect } from 'react';
import {
  Button,
  Stack,
  Typography,
} from '@material-ui/core';
import { useExperience } from '../contexts/experience';
import String from './String';

const REPLAY_OVERLAY_DURATION = 30000;

function Replay() {
  const { replay, setVisited, visited } = useExperience();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisited([]);
    }, REPLAY_OVERLAY_DURATION);

    return () => clearTimeout(timeout);
  }, [visited]);

  return (
    <Stack justifyContent="center" width="100%" height="100%">
      <Stack p={4} spacing={3}>
        <Typography variant="title">
          <String s="replay_title" />
        </Typography>
        <Typography>
          <String s="replay_message" />
        </Typography>
        <Stack direction="row" width="100%" spacing={2}>
          <Button variant="outlined" color="secondary" onClick={() => setVisited([])} autoFocus>
            <String s="cancel" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={replay}
          >
            <String s="confirm" />
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Replay;
