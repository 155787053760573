import React from 'react';
import { Source, Layer, Marker } from 'react-map-gl';
import iconLight from '../../assets/svg/spot-marker-light.svg';
import iconDark from '../../assets/svg/spot-marker-dark.svg';
import { DEADBAND_CIRCLE_STYLE_OPTS, RADIUS_CIRCLE_STYLE_OPTS } from '../../config/map';
import { metersToPixelsAtMaxZoom } from '../utils/map';
import palette from '../../config/themes/palette';
import { useTheme } from '../contexts/theme';

const MapSpotLayer = ({ position, activePosIds }) => {
  const { theme } = useTheme();

  const center = [position.lon, position.lat];
  const isActive = activePosIds?.includes(position.id);

  const colors = theme === 'day' ? {
    active: palette.yellow,
    default: palette.brand,
  } : {
    active: palette.yellow,
    default: palette.white,
  };

  const feature = {
    type: 'Feature',
    id: `${position.id}_feature`,
    geometry: {
      type: 'Point',
      coordinates: center,
    },
  };

  const radiusCircleSize = metersToPixelsAtMaxZoom(
    position.radius,
    position.lat,
  );

  const deadbandCircleSize = metersToPixelsAtMaxZoom(
    (position.radius + position.deadband),
    position.lat,
  );

  const radiusLayerStyle = {
    id: `${position.id}_radius_circle`,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, radiusCircleSize],
        ],
        base: 2,
      },
      'circle-stroke-color': isActive ? colors.active : colors.default,
      'circle-color': isActive ? colors.active : colors.default,
      'circle-stroke-opacity': RADIUS_CIRCLE_STYLE_OPTS.opacity,
      'circle-stroke-width': RADIUS_CIRCLE_STYLE_OPTS.weight,
      'circle-stroke-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-opacity': RADIUS_CIRCLE_STYLE_OPTS.fillOpacity,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
    },
  };

  const deadbandLayerStyle = {
    id: `${position.id}_deadband_circle`,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, deadbandCircleSize],
        ],
        base: 2,
      },
      'circle-stroke-color': isActive ? colors.active : colors.default,
      'circle-color': isActive ? colors.active : colors.default,
      'circle-stroke-opacity': DEADBAND_CIRCLE_STYLE_OPTS.opacity,
      'circle-stroke-width': DEADBAND_CIRCLE_STYLE_OPTS.weight,
      'circle-stroke-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-opacity': DEADBAND_CIRCLE_STYLE_OPTS.fillOpacity,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
    },
  };

  return (
    <>
      <Source
        id={`${position.id}_source`}
        type="geojson"
        data={feature}
      >
        <Layer {...radiusLayerStyle} />
        <Layer {...deadbandLayerStyle} />
      </Source>

      <Marker
        longitude={position.lon}
        latitude={position.lat}
        anchor="center"
      >
        <img alt="agami" src={theme === 'day' ? iconLight : iconDark} />
      </Marker>
    </>
  );
};

export default MapSpotLayer;
