const fr = {
  welcome_to_agami: 'Bienvenue sur Agami Route',
  login: 'CONNEXION',
  send_email: 'ENVOYER UN EMAIL',
  login_with_password: 'Connexion avec mot de passe',
  login_with_email: 'Connexion avec email seulement',
  lang: 'Française',
  select_lang: 'Choisissez votre langue',
  day: 'Jour',
  night: 'Nuit',
  confirm: 'Confirmer',
  continue: 'Continuer',
  cancel: 'Annuler',
  refresh: 'Recharger',
  yes_continue: 'Oui, continuer',
  start: 'Démarrer',
  back: 'Retour',
  wait: 'Veuillez patienter...',
  headset: 'Veuillez utiliser le casque fourni pour une meilleure expérience',
  raise_volume: 'Augmentez le volume de votre appareil',
  sound: 'Entendez-vous le son correctement ?',
  wait_location: 'Vérification de la précision de la localisation',
  gps: 'L\'application tente de récupérer votre position',
  gps_error: 'Un problème est survenu avec le GPS de ce téléphone',
  gps_quality_bad: 'Précision de la localisation mauvaise',
  gps_quality_bad_details: 'Vous pourriez rencontrer des problèmes avec la lecture automatique',
  gps_quality_average: 'Précision de la localisation acceptable',
  gps_quality_good: 'Précision de la localisation excellente',
  cookies: 'Il y a déjà une visite en cours',
  cookies_action: 'Souhaitez-vous continuer ou recommencer ?',
  cookies_delete: 'Changer d\'expérience',
  cookies_maintain: 'Continuer l\'expérience',
  unlocking: 'Si vous avez besoin d\'utiliser l\'application pendant la visite, déverrouillez-la en utilisant ce code',
  unlock: 'Confirmer le déverrouillage ?',
  unlock_subtitle: 'Le déverrouillage de l\'écran est réservé aux administrateurs uniquement',
  areyouready: 'Prêt à commencer ?',
  welcome: 'BIENVENUE',
  map: 'Carte',
  code: 'Code manuel',
  insert_code: 'Insérez un code manuel et appuyez sur le bouton "Lecture"',
  faq: [
    {
      q: 'Problèmes de connexion ?',
      a: 'Si vous rencontrez des problèmes de connexion, essayez de...',
    },
    {
      q: 'Autres questions fréquentes',
      a: 'Si vous rencontrez des problèmes de connexion, essayez de...',
    },
  ],
  mobile_only: 'Cette application a été conçue pour les appareils mobiles uniquement.',
  screen_locked: 'Écran verrouillé',
  swipe_left: 'Faites glisser vers la gauche pour déverrouiller',
  admin_title: 'Contrôles administrateur',
  admin_close: 'Fermer',
  admin_test: 'Test audio',
  admin_reset_soft: 'Changer d\'expérience',
  admin_reset_hard: 'Réinitialiser l\'application',
  admin_reset_hard_confirm: 'Voulez-vous réinitialiser l\'application ?',
  admin_reset_hard_desc: 'La réinitialisation de l\'application effacera toutes les expériences enregistrées et leur contenu. Vous devrez les télécharger à nouveau.',
  admin_logout: 'Déconnexion',
  search_gps: 'En attente de la couverture GPS',
  select_experience: 'Sélectionner une expérience',
  no_experience: 'Il semble que vous n\'ayez aucune expérience disponible.',
  pause: 'Pause',
  stop: 'Arrêter',
  stop_all: 'Tout arrêter',
  restart: 'Reprendre',
  beforestart: 'Appuyez sur le bouton "Démarrer" lorsque vous êtes prêt à commencer l\'expérience.',
  ispause: 'L\'EXPÉRIENCE EST EN PAUSE',
  ispause_restart: 'Cliquez sur le bouton "Lecture" pour la reprendre.',
  play: 'Lecture',
  offline_experience: 'Expérience hors ligne',
  replay_title: 'Le contenu est disponible pour être rejoué',
  replay_message: 'Il y a du contenu disponible que vous avez déjà écouté. Souhaitez-vous le rejouer ?',
  redeem_exp_title: 'Échanger l\'expérience',
  redeem_exp_result_title: 'Expérience échangée',
  redeem_exp_button: 'ÉCHANGER',
  go_to_exp_button: 'COMMENCER',
  check_email_login: 'Consultez votre e-mail pour confirmer la connexion.',
  redeemed_until_days: 'Vous avez accès pour les prochains',
  redeemed_until_date: 'Vous avez accès jusqu\'au',
  alreadly_redeemed: 'Il semble que vous ayez déjà utilisé ce code auparavant. Vous devriez déjà avoir cette expérience.',
  redeem_ok: 'L\'expérience a été créée avec succès pour vous.',

  gps_location_unavailable: 'Services de localisation non disponibles',
  gps_location_unavailable_desc: 'Les services de localisation sont soit désactivés, soit les autorisations d\'accès n\'ont pas été accordées. Veuillez suivre les instructions pour vérifier les paramètres corrects.',
  gps_location_open_phone_settings: 'Ouvrir les paramètres du téléphone',
  gps_location_browser_in_use: 'le navigateur que vous utilisez',

  gps_location_android_1: 'Allez dans "Position"',
  gps_location_android_2: 'Vérifiez que "Utiliser la position" est activé',
  gps_location_android_3: 'Ouvrez "Autorisations de position de l\'application"',
  gps_location_android_4: 'Faites défiler jusqu\'au bas de la section “Non autorisé” et vérifiez si',
  gps_location_android_5: 'est là',
  gps_location_android_6: 'Si c\'est le cas, cliquez dessus et sélectionnez soit "Demander à chaque fois" soit "Autoriser uniquement pendant l\'utilisation de l\'application"',

  gps_location_ios_1: 'Allez dans "Confidentialité et sécurité"',
  gps_location_ios_2: 'Vérifiez que "Services de localisation" est activé',
  gps_location_ios_3: 'Revenez aux paramètres du téléphone',
  gps_location_ios_4: 'Faites défiler vers le bas et recherchez les paramètres de',
  gps_location_ios_5: 'À l\'intérieur des paramètres, recherchez "Position"',
  gps_location_ios_6: 'Définissez-le soit sur "Demander" soit sur "Autoriser"',

  gps_location_chrome_1: 'Ouvrez Chrome',
  gps_location_chrome_2: 'Accédez à',
  gps_location_chrome_3: 'Ouvrez les paramètres du site en cliquant sur l\'icône des curseurs juste à gauche de l\'adresse du site web',
  gps_location_chrome_4: 'Sélectionnez "Autorisations"',
  gps_location_chrome_5: 'Vérifiez que "Position" est autorisé',

  gps_location_safari_1: 'Ouvrez Safari',
  gps_location_safari_2: 'Accédez à',
  gps_location_safari_3: 'Ouvrez les paramètres du site en cliquant sur l\'icône "Aa" juste à gauche de l\'adresse du site web',
  gps_location_safari_4: 'Sélectionnez "Paramètres du site web"',
  gps_location_safari_5: 'Sous la section "Autoriser", vérifiez que "Position" est défini sur "Demander" ou "Autoriser"',
  gps_location_safari_6: 'Cliquez sur "Terminé"',

  gps_location_browser_1: 'Ouvrez votre navigateur préféré',
  gps_location_browser_2: 'Accédez à',
  gps_location_browser_3: 'Ouvrez les paramètres du site web',
  gps_location_browser_4: 'Vérifiez que la permission d\'accès à la position est définie sur "Demander" ou "Autoriser"',

  go_without_gps: 'Procédez sans localisation',
  go_without_gps_warning: 'Vous pourrez jouer un contenu uniquement en entrant manuellement son code',

  read_instructions: 'Lire les instructions',

  go_home: 'Accueil',
};

export default fr;
