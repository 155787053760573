import React, { useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from './components/Routes';

import ThemeProvider from './contexts/theme';
import LanguageProvider from './contexts/language';
import ExperienceProvider, { useExperience } from './contexts/experience';
import SessionProvider from './contexts/session';

import Setup from './views/Setup';
import Experience from './views/Experience';
import RedeemExp from './views/RedeemExp';

import './css/App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import RedeemExpAnonymous from './views/RedeemExpAnonymous';

function Views() {
  const { config } = useExperience();

  const views = useMemo(() => [
    {
      name: 'Setup',
      path: '/setup',
      component: Setup,
    },
    {
      name: 'Redeem Experience',
      path: '/redeem-experience',
      component: RedeemExp,
    },
    {
      name: 'Redeem Experience Anonymously',
      path: '/redeem-experience-anonymous',
      component: RedeemExpAnonymous,
    },
    ...(config ? [{
      name: 'Experience',
      path: '/experience',
      component: Experience,
    }] : []),
  ], [config]);

  return <Routes views={views} />;
}

export default function App() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <LanguageProvider>
        <ThemeProvider>
          <Router>
            <SessionProvider>
              <ExperienceProvider>
                <Views />
              </ExperienceProvider>
            </SessionProvider>
          </Router>
        </ThemeProvider>
      </LanguageProvider>
    </>
  );
}
