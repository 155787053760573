import React from 'react';
import {
  Stack, Box,
} from '@material-ui/core';
import { useTheme } from '../contexts/theme';

const root = { width: '100%', height: '100%', overflowX: 'hidden' };

const content = { flexGrow: 1, overflowX: 'hidden', position: 'relative' };

function Fullscreen({ children }) {
  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      {children}
    </Box>
  );
}

function Framed({ children }) {
  const { styles } = useTheme();
  const background = styles.palette.background.default;
  const textureWrap = {
    background,
    padding: '2vh',
    height: '100%',
  };
  const innerWrap = {
    background,
    height: '100%',
  };
  return (
    <div style={textureWrap}>
      <div style={innerWrap}>{children}</div>
    </div>
  );
}

export default function Layout({
  fullscreen,
  framed,
  bgColor,
  top,
  bottom,
  direction,
  justify,
  align,
  children,
}) {
  let layout = (
    <Stack style={{ ...root, backgroundColor: bgColor || 'transparent' }}>
      {top}
      <Stack
        direction={direction || 'column'}
        justifyContent={justify || 'space-evenly'}
        alignItems={align || 'center'}
        style={{ ...content, overflowY: fullscreen ? 'hidden' : 'auto' }}
      >
        {children}
      </Stack>
      {bottom}
    </Stack>
  );
  layout = fullscreen ? <Fullscreen>{layout}</Fullscreen> : layout;
  layout = framed ? <Framed>{layout}</Framed> : layout;
  return layout;
}
