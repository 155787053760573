import { toast } from 'react-toastify';
import palette from '../../config/themes/palette';

export default function useToast() {
  const success = (msg) => {
    toast.success(msg, {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: palette.green,
        color: palette.brand,
      },
    });
  };

  const error = (msg) => {
    toast.error(msg, {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: palette.red,
        color: palette.white,
      },
    });
  };

  return { success, error };
}
