import { useMemo } from 'react';
import flatten from 'lodash/flatten';
import { useExperience } from '../contexts/experience';
import MapSpotLayer from './MapSpotLayer';

const MapSpots = () => {
  const {
    positions,
    active,
    config,
  } = useExperience();

  const activePosIds = useMemo(
    () => active?.map((audio) => audio?.spot?.position),
    [active],
  );

  const enrichedPositions = useMemo(() => {
    if (!positions || !config) return;
    const everySpot = flatten(
      config.experience.patterns.map((pattern) => pattern.spots),
    );
    return positions.map((pos) => {
      const spotData = everySpot.find((spot) => spot.position === pos.id);
      return ({
        ...pos,
        label: spotData?.label ?? '',
        description: spotData?.description ?? '',
      });
    });
  }, [positions, config]);

  return (
    // eslint-disable-next-line
    <>
      {enrichedPositions?.map((position) => (
        <MapSpotLayer
          key={`${position.id}_layer`}
          activePosIds={activePosIds}
          position={position}
        />
      ))}
    </>
  );
};

export default MapSpots;
