export const validateConfig = (config) => {
  // geo config
  if (!config.geo) {
    console.error('Missing "geo" object');
    return false;
  }

  if (!config.geo.positions && !Array.isArray(config.geo.positions)) {
    console.error('Error in "geo.position" array');
    return false;
  }

  config.geo.positions.forEach((pos, idx) => {
    // if (!pos.id || !pos.lon || !pos.lat) {
    if (!pos.id) {
      console.error(`Missing info in "geo.positions[${idx}]"`, pos);
      return false;
    }
  });

  // audio config
  if (!config.audio) {
    console.error('Missing "audio" object');
    return false;
  }

  if (!config.audio.sounds && !Array.isArray(config.audio.sounds)) {
    console.error('Error in "audio.sounds" array');
    return false;
  }

  config.audio.sounds.forEach((sound, idx) => {
    if (!sound.id || !sound.url) {
      console.error(`Missing info in "audio.sound[${idx}]"`, sound);
      return false;
    }
  });

  // experience config
  if (!config.experience) {
    console.error('Missing "experience" object');
    return false;
  }

  if (!config.experience.patterns && !Array.isArray(config.experience.patterns)) {
    console.error('Error in "experience.patterns" array');
    return false;
  }

  config.experience.patterns.forEach((pattern, patternIdx) => {
    if (!pattern.id || !pattern.spots || !Array.isArray(pattern.spots)) {
      console.error(`Missing info in "experience.pattern[${patternIdx}]"`, pattern);
      return false;
    }

    pattern.spots.forEach((spot, spotIdx) => {
      if (!spot.id || !spot.audio || (!spot.position && !spot.code)) {
        console.error(`Missing info in "experience.pattern[${patternIdx}].spots[${spotIdx}]"`, spot);
        return null;
      }
    });
  });

  // everything is ok
  return true;
};

export const EMPTY_GEOXP_CONFIG = {
  geo: {
    positions: [],
    options: {},
  },
  audio: {
    sounds: [],
  },
  experience: {
    patterns: [],
    options: {},
  },
};

export const getPositionsFromConfig = (geoConfig) => geoConfig.positions.map((pos) => ({
  ...pos,
  radius: pos.radius || geoConfig.options.defaultRadius,
  deadband: pos.deadband || geoConfig.options.defaultDeadband,
}));

export const configHasManualSpots = (config) => {
  if (!config) return false;

  let manualSpot = false;
  const patterns = config?.experience?.patterns || [];
  patterns.forEach((p) => {
    if (p.spots.find((s) => s.code)) {
      manualSpot = true;
    }
  });
  return manualSpot;
};

export const configHasGeoSpots = (config) => {
  if (!config) return false;

  let geoSpot = false;
  const patterns = config?.experience?.patterns || [];
  patterns.forEach((p) => {
    if (p.spots.find((s) => s.position)) {
      geoSpot = true;
    }
  });
  return geoSpot;
};
