import { useCallback, useEffect, useState } from 'react';
import { TILES_PRECACHE_NAME } from '../../config/precache';
import { useNetwork } from './useNetwork';

export const useTilesCache = () => {
  const [loaded, setLoaded] = useState(false);

  const networkAvailable = useNetwork();

  const refreshTilesCache = useCallback(async () => {
    const now = new Date();
    const cache = await caches.open(TILES_PRECACHE_NAME);
    const requests = await cache.keys();
    const promises = requests.map(async (request) => {
      const response = await cache.match(request, { ignoreVary: true });
      const clonedReponse = response.clone();
      // add 24 hours for good measure
      const newExpiration = new Date(now.getTime() + 86400 * 1000);
      clonedReponse.headers.set('Expires', newExpiration.toGMTString());
      clonedReponse.headers.set('Cache-Control', 'max-age=2147483648,s-maxage=2147483648,stale-while-revalidate=2147483648,stale-if-error=2147483648');
      await cache.put(request, clonedReponse);
      console.info('Tile cache refreshed', request.url);
    });
    await Promise.all(promises);
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!networkAvailable) refreshTilesCache();
    else setLoaded(true);
  }, []);

  return loaded;
};
