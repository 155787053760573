import React from 'react';
import Sheet from 'react-modal-sheet';
import { PiXCircle } from 'react-icons/pi';
import { Box, IconButton } from '@material-ui/core';
import Player from './Player';
import { useTheme } from '../contexts/theme';

const PlayerBottomSheet = ({ openSheet, setOpenSheet }) => {
  const { styles } = useTheme();
  const backgroundColor = styles.palette.background.default;
  return (
    <Sheet
      isOpen={openSheet}
      onClose={() => setOpenSheet(false)}
    >
      <Sheet.Container style={{ backgroundColor }}>
        <Sheet.Header />
        <Sheet.Content>
          <Box
            position="absolute"
            top="-30px"
            right={0}
          >
            <IconButton onClick={() => setOpenSheet(false)}>
              <PiXCircle />
            </IconButton>
          </Box>
          <Player />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default PlayerBottomSheet;
