import React, { useEffect, useMemo } from 'react';
import Mapbox, { useMap } from 'react-map-gl';
import * as MapboxJs from 'mapbox-gl';
import { Box } from '@material-ui/core';
import MapSpots from './MapSpots';
import { MAPBOX_DARK_MAP_STYLE, MAPBOX_LIGHT_MAP_STYLE, MAPBOX_TOKEN } from '../../config/map';
import { useTheme } from '../contexts/theme';
import { getBounds } from '../utils/map';
import { useTilesCache } from '../hooks/useTilesCache';
import Loading from './Loading';
import { useSession } from '../contexts/session';
import AccuracyInfo from './AccuracyInfo';

const Map = ({
  positions, styleOverride, loaded, userLocation,
}) => {
  const { offline } = useSession();
  const { theme } = useTheme();
  const tilesCache = useTilesCache();

  const bounds = useMemo(() => getBounds(positions), [positions]);
  const mapStyle = useMemo(() => styleOverride || (theme === 'day' ? MAPBOX_LIGHT_MAP_STYLE : MAPBOX_DARK_MAP_STYLE), [theme, styleOverride]);

  return (
    <Box width="100%" height="100%" position="relative">
      {tilesCache ? (
        <Mapbox
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle={mapStyle}
          attributionControl={false}
          style={{
            height: '100%',
            width: '100%',
          }}
          initialViewState={{
            bounds,
            fitBoundsOptions: {
              padding: {
                top: 20, left: 20, right: 20, bottom: 20,
              },
            },
          }}
          onLoad={loaded && loaded}
          interactive={!offline}
          refreshExpiredTiles={false}
          fog={undefined}
          styleDiffing={false}
        >
          {!!userLocation && <UserLocation controlCamera={!offline} />}
          {positions?.length > 0 && <MapSpots />}
          <Box
            position="absolute"
            bottom={0}
            right={0}
          >
            <AccuracyInfo />
          </Box>
        </Mapbox>
      ) : (
        <Loading />
      )}
    </Box>
  );
};

function UserLocation({ controlCamera }) {
  const { current: map } = useMap();

  useEffect(() => {
    const control = new MapboxJs.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
      showUserHeading: true,
      showUserLocation: true,
    });

    if (!controlCamera) {
      // hacky workaround for the fact that mapbox doesn't let you disable camera auto-tracking
      // eslint-disable-next-line
      control._updateCamera = () => { };
    }

    map.addControl(control);
    map.on('load', () => control.trigger());

    return () => map.removeControl(control);
  }, [map]);

  return null;
}

export default Map;
