import { useState } from 'react';
import {
  Box, Button, Stack, TextField, Typography,
} from '@material-ui/core';
import { useExperience } from '../contexts/experience';
import Layout from './Layout';
import String from './String';
import { useTheme } from '../contexts/theme';

function ManualCode() {
  const { playManually, started, isActive } = useExperience();
  const { styles } = useTheme();

  const [code, setCode] = useState('');
  const [error, setError] = useState();

  const handleCodeChange = (e) => {
    setError();
    setCode(e.target.value);
  };
  const handleCodeFocus = () => {
    setError();
    setCode('');
  };
  const handleConfirm = () => {
    setError();
    const playbackError = playManually(code);
    if (playbackError) setError(playbackError);
    else setCode('');
  };
  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      handleConfirm();
    }
  };

  return (
    <Layout bgColor={styles.palette.background.default}>
      <Stack pt={3} spacing={3} style={{ marginInline: 50 }}>
        <Box style={{ textAlign: 'center' }}>
          <Typography variant="subtitle"><String s="insert_code" /></Typography>
        </Box>
        <Box style={{ textAlign: 'center' }}>
          <TextField
            color="secondary"
            variant="standard"
            onChange={handleCodeChange}
            onFocus={handleCodeFocus}
            onKeyUp={handleKeyUp}
            value={code}
            inputProps={{ type: 'text', style: { fontSize: 40, textAlign: 'center' } }}
            disabled={!started || isActive}
          />
          {error
            ? <Typography>{error}</Typography>
            : <Typography style={{ opacity: 0 }}>Not visible</Typography>}
        </Box>
        <Box style={{ textAlign: 'center' }}>
          <Button
            disabled={code.length === 0}
            color="secondary"
            variant="contained"
            onClick={handleConfirm}
          >
            <String s="play" />
          </Button>
        </Box>
      </Stack>
    </Layout>
  );
}

export default ManualCode;
