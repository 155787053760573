import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import palette from './config/themes/palette';
import { SENTRY_DSN_URL } from './app/utils/services';
import pkgInfo from '../package.json';

const { version } = pkgInfo;

Sentry.init({
  release: version,
  dsn: SENTRY_DSN_URL,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/route\.mezzoforte\.design/],
  // Session Replay
  // This sets the sample rate at 10%.
  // You may want to change it to 100%
  // while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // If you're not already sampling the entire session,
  // change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => {
    toast.success('App has been installed correctly', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: palette.yellow,
        color: palette.brand,
      },
    });
  },
  onUpdate: () => {
    toast.info('A new version of the app is available. Close and reopen it to update', {
      position: 'bottom-center',
      autoClose: 8000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: palette.yellow,
        color: palette.brand,
      },
    });
  },
});
