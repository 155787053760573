import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import PreviousIcon from '@material-ui/icons/FastRewind';
import NextIcon from '@material-ui/icons/FastForward';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useExperience } from '../contexts/experience';
import String from './String';
import { formatTimer } from '../utils/utils';

const btnStyle = {
  position: 'relative',
  border: '2px solid',
  height: '6rem',
  width: '6rem',
};
const countDownStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
};

const progressStyle = { position: 'absolute', height: '107%', width: '107%' };

function Player() {
  const { active, stopAll } = useExperience();

  const [selected, setSelected] = useState(0);

  const isGeo = useMemo(() => {
    const geo = active.filter((s) => s.spot?.position);
    if (geo && geo.length > 0) return true;
    return false;
  }, [active]);

  return (
    <Stack justifyContent="center" position="relative" width="100%" height="100%">
      <Carousel
        showThumbs={false}
        autoPlay={false}
        infiniteLoop={false}
        showStatus={false}
        showIndicators={false}
        selectedItem={selected}
        onChange={setSelected}
        renderArrowPrev={(clickHandler, hasPrev) => (
          <Button
            color="secondary"
            onClick={clickHandler}
            disabled={!hasPrev}
            variant="contained"
            style={{
              opacity: hasPrev ? 1 : 0,
              position: 'absolute',
              left: '2vh',
              bottom: '2vh',
              paddingInline: '10px',
              zIndex: 40,
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: '2vh' }} />
          </Button>
        )}
        renderArrowNext={(clickHandler, hasNext) => (
          <Button
            color="secondary"
            onClick={clickHandler}
            disabled={!hasNext}
            variant="contained"
            style={{
              opacity: hasNext ? 1 : 0,
              position: 'absolute',
              right: '2vh',
              bottom: '2vh',
              paddingInline: '10px',
              zIndex: 40,
            }}
          >
            <ArrowForwardIosIcon style={{ fontSize: '2vh' }} />
          </Button>
        )}
      >
        {active.map((audioData) => (
          <AudioPlayer
            key={audioData.spot?.id}
            data={audioData}
            onUnmount={() => setSelected(0)}
          />
        ))}
      </Carousel>
      {active && active.length > 1 && !isGeo && (
        <Button
          color="secondary"
          variant="contained"
          onClick={stopAll}
          style={{
            position: 'absolute', bottom: '2vh', left: '50%', transform: 'translate(-50%, 0)',
          }}
        >
          <String s="stop_all" />
        </Button>
      )}
    </Stack>
  );
}

function AudioPlayer({ data, onUnmount }) {
  const [progress, setProgress] = useState(0);

  const description = data?.spot?.description;

  const audio = data?.audio;
  // eslint-disable-next-line
  const duration = audio?._duration;
  const perc = (progress / duration) * 100;
  const elapsed = formatTimer(progress);

  const skip = (seconds) => {
    const newProgress = progress + seconds;
    if (newProgress > 0 && newProgress < duration) audio?.seek(newProgress);
  };

  const isGeo = useMemo(() => {
    if (data?.spot?.position) return true;
    return false;
  }, [data]);

  useEffect(() => {
    const seek = setInterval(() => {
      try {
        const currentProgress = audio?.seek();
        setProgress(currentProgress);
      } catch {
        // do nothing
      }
    }, 100);
    return () => clearInterval(seek);
  }, [audio]);

  useEffect(() => () => onUnmount(), []);

  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
      height="100%"
      pt={4}
      px={3}
    >
      <Typography
        color="textSecondary"
        variant="h5"
        fontWeight="bold"
      >
        {data?.spot?.label}
      </Typography>
      <Grid
        container
        columnGap={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <IconButton color="secondary" onClick={() => skip(-10)}>
            <PreviousIcon style={{ fontSize: '2rem' }} />
          </IconButton>
          <Typography fontSize="0.75rem" color="textSecondary" mt={1}>
            10 s
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => (audio?.playing() ? audio.pause() : audio.play())}
            style={btnStyle}
            color="secondary"
          >
            <Box style={countDownStyle}>
              {audio.playing() ? <PauseIcon style={{ fontSize: '2rem' }} /> : <PlayIcon style={{ fontSize: '2rem' }} />}
            </Box>
            <CircularProgress
              variant="determinate"
              value={Math.floor(perc)}
              style={progressStyle}
              thickness={2}
              color="secondary"
            />
          </IconButton>
          <Typography fontSize="0.75rem" color="textSecondary" mt={1}>
            {elapsed || '00 : 00'}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton color="secondary" onClick={() => skip(10)}>
            <NextIcon style={{ fontSize: '2rem' }} />
          </IconButton>
          <Typography fontSize="0.75rem" color="textSecondary" mt={1}>
            10 s
          </Typography>
        </Grid>
      </Grid>
      {!isGeo && (
        <Box>
          <Button color="secondary" variant="outlined" onClick={() => audio?.stop()}>
            <String s="stop" />
          </Button>
        </Box>
      )}
      {description
        ? (
          <Box
            flex={1}
            width="100%"
            overflow="scroll"
            borderTop="1px solid gray"
            py={3}
          >
            <Typography
              textAlign="left"
              fontStyle="italic"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {description}
            </Typography>
          </Box>
        ) : (
          <Typography
            color="textSecondary"
            variant="h5"
            fontWeight="bold"
            visibility="hidden"
          >
            {data?.spot?.label}
          </Typography>
        )}
    </Stack>
  );
}

export default Player;
