import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Grid,
  Stack,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowRightAlt';
import Layout from './Layout';
import ThemeSwitcher from './ThemeSwitcher';
import Image from './Image';

function SetupStep({
  title,
  pretitle,
  text,
  image,
  media,
  form,
  action,
  actionClick,
  auxAction,
  auxActionClick,
  next,
  disabled,
  themeSwitcher,
  stepNav,
  ...p
}) {
  return (
    <Layout justify="space-between">
      <Stack flexGrow={1} justifyContent="center" width="90%" maxWidth="600px">
        <Stack textAlign="center" spacing={2} mb="3vh">
          {pretitle && (
            <Typography variant="pretitle">
              {pretitle}
            </Typography>
          )}
          <Typography variant="title">
            {title}
          </Typography>
          {text && (
            <Typography variant="text">
              {text}
            </Typography>
          )}
          {media}
          {(image || form) && (
          <Box>
            {image && <Image i={image} style={{ height: '15vh' }} />}
            {form}
          </Box>
          )}
          {action && (
          <Box mt={3}>
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              {...(actionClick ? { onClick: actionClick } : { component: Link, to: next })}
              fullWidth
            >
              {action}
            </Button>
          </Box>
          )}
          {auxAction && auxActionClick && (
          <Box>
            <Button
              color="primary"
              variant="contained"
              mt={2}
              disabled={disabled}
              fullWidth
              onClick={(e) => auxActionClick(e)}
            >
              {auxAction}
            </Button>
          </Box>
          )}
        </Stack>
      </Stack>
      <Box>
        {stepNav && <StepNav {...p} />}
        {themeSwitcher && <ThemeSwitcher />}
      </Box>
    </Layout>
  );
}

function StepNav({ step, steps, back }) {
  return (
    <Grid container alignItems="center" height="50px" width="10rem">
      <Grid item xs={3} align="center">
        {back && (
          <Link to={back}>
            <Typography fontSize="1rem" color="textPrimary">
              <BackIcon
                style={{ transform: 'rotate(-180deg)', fontSize: '40px' }}
              />
            </Typography>
          </Link>
        )}
      </Grid>
      <Grid item xs={6} align="center">
        <Typography fontSize="1.5rem" color="textPrimary">
          {step}
          /
          {steps.length}
        </Typography>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
}

export default SetupStep;
