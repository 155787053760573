import en from './en';
import fr from './fr';
import it from './it';
import es from './es';

const languages = {
  en,
  fr,
  it,
  es,
};

export default languages;

const parseUrlParams = (url) => {
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  vars.forEach((el) => {
    const pair = el.split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  });
  return params;
};

export const default_language = parseUrlParams(window.location.href).lang || 'en';
