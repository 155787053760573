import { Box, Stack, Typography } from '@material-ui/core';
import React from 'react';
import { useExperience } from '../contexts/experience';
import String from './String';

const AccuracyInfo = () => {
  const { location, config } = useExperience();
  const currentAccuracy = location?.coords?.accuracy;
  const requiredAccuracy = config?.geo?.options?.accuracy;
  if (!currentAccuracy) return null;
  return (
    <Box
      pr={1}
      pb={0.5}
      position="relative"
    >
      {requiredAccuracy && currentAccuracy > requiredAccuracy
        ? (
          <Stack alignItems="flex-end">
            <Typography
              variant="small"
              fontWeight="bold"
              color="error"
            >
              <String s="gps_quality_bad" />
            </Typography>
            <Typography
              fontSize="0.6rem"
              color="error"
            >
              <String s="gps_quality_bad_details" />
            </Typography>
          </Stack>
        ) : (
          <Typography
            variant="text"
            fontFamily="monospace"
            fontSize="0.5rem"
          >
            acc
            {' '}
            {currentAccuracy.toFixed(0)}
            {' '}
            m
          </Typography>
        )}
    </Box>
  );
};

export default AccuracyInfo;
