import packageJson from '../../../package.json';

const VERSION = packageJson.version;

const divStyle = {
  opacity: 0.6,
};

export default function AppVersion() {
  return (
    <div style={divStyle}>
      <small>
        <em>
          v
          {VERSION}
        </em>
      </small>
    </div>
  );
}
