const es = {
  welcome_to_agami: 'Bienvenido a Agami Route',
  login: 'INICIAR SESIÓN',
  send_email: 'ENVIAR CORREO ELECTRÓNICO',
  login_with_password: 'Iniciar sesión con contraseña',
  login_with_email: 'Iniciar sesión solo con correo electrónico',
  lang: 'Español',
  select_lang: 'Selecciona tu idioma',
  day: 'Día',
  night: 'Noche',
  confirm: 'Confirmar',
  continue: 'Continuar',
  cancel: 'Cancelar',
  refresh: 'Recargar',
  yes_continue: 'Sí, continuar',
  start: 'Comenzar',
  back: 'Atrás',
  wait: 'Por favor espera...',
  headset: 'Por favor, usa los auriculares proporcionados para una mejor experiencia',
  raise_volume: 'Aumenta el volumen de tu dispositivo',
  sound: '¿Puedes escuchar el sonido correctamente?',
  wait_location: 'Verificación de precisión de la ubicación',
  gps: 'La aplicación está intentando obtener tu ubicación',
  gps_error: 'Hay un problema con el GPS de este teléfono',
  gps_quality_bad: 'Precisión de la ubicación mala',
  gps_quality_bad_details: 'Es posible que experimentes problemas con la reproducción automática',
  gps_quality_average: 'Precisión de la ubicación aceptable',
  gps_quality_good: 'Precisión de la ubicación excelente',
  cookies: 'Ya hay un recorrido en curso',
  cookies_action: '¿Te gustaría continuar o empezarlo de nuevo?',
  cookies_delete: 'Cambiar experiencia',
  cookies_maintain: 'Continuar experiencia',
  livorno: '"L" como Livorno',
  unlocking: 'Si necesitas usar la aplicación durante la visita, desbloquéala usando este código',
  unlock: '¿Confirmar desbloqueo?',
  unlock_subtitle: 'Desbloquear la pantalla es solo para administradores',
  areyouready: '¿Listo para empezar?',
  welcome: 'BIENVENIDO',
  map: 'Mapa',
  code: 'Código manual',
  insert_code: 'Inserta un código manual y pulsa el botón "Reproducir"',
  faq: [
    {
      q: '¿Problemas de conexión?',
      a: 'Si estás experimentando problemas de conexión, intenta...',
    },
    {
      q: 'Otras preguntas frecuentes',
      a: 'Si estás experimentando problemas de conexión, intenta...',
    },
  ],
  mobile_only: 'Esta aplicación fue diseñada para dispositivos móviles únicamente.',
  screen_locked: 'Pantalla bloqueada',
  swipe_left: 'Desliza hacia la izquierda para desbloquear',
  admin_title: 'Controles de administrador',
  admin_close: 'Cerrar',
  admin_test: 'Prueba de audio',
  admin_reset_soft: 'Cambiar experiencia',
  admin_reset_hard: 'Restablecer aplicación',
  admin_reset_hard_confirm: '¿Quieres restablecer la aplicación?',
  admin_reset_hard_desc: 'El restablecimiento de la aplicación borrará todas las experiencias guardadas y su contenido. Deberás descargarlas nuevamente.',
  admin_logout: 'Cerrar sesión',
  search_gps: 'Esperando cobertura GPS',
  select_experience: 'Selecciona una experiencia',
  no_experience: 'Parece que no tienes ninguna experiencia disponible.',
  pause: 'Pausa',
  stop: 'Detener',
  stop_all: 'Detener todo',
  restart: 'Reanudar',
  beforestart: 'Toca el botón "Comenzar" cuando estés listo para comenzar la experiencia.',
  ispause: 'LA EXPERIENCIA ESTÁ EN PAUSA',
  ispause_restart: 'Haz clic en el botón "Reproducir" para reanudarla.',
  play: 'Reproducir',
  offline_experience: 'Experiencia sin conexión',
  replay_title: 'El contenido está disponible para repetir',
  replay_message: 'Hay contenido disponible que ya has escuchado. ¿Quieres repetirlo?',
  redeem_exp_title: 'Canjear experiencia',
  redeem_exp_result_title: 'Experiencia canjeada',
  redeem_exp_button: 'CANJEAR',
  go_to_exp_button: 'EMPEZAR',
  check_email_login: 'Revisa tu correo electrónico para confirmar el inicio de sesión.',
  redeemed_until_days: 'Tienes acceso durante los próximos',
  redeemed_until_date: 'Tienes acceso hasta el',
  alreadly_redeemed: 'Parece que ya usaste este código antes. Deberías tener esta experiencia.',
  redeem_ok: 'La experiencia se ha creado correctamente.',

  gps_location_unavailable: 'Servicios de ubicación no disponibles',
  gps_location_unavailable_desc: 'Los servicios de ubicación están desactivados o no se ha concedido permiso de acceso. Por favor, sigue las instrucciones para verificar la configuración correcta.',
  gps_location_open_phone_settings: 'Abrir configuración del teléfono',
  gps_location_browser_in_use: 'el navegador que estás utilizando',

  gps_location_android_1: 'Ve a "Ubicación"',
  gps_location_android_2: 'Verifica que "Usar ubicación" esté habilitado',
  gps_location_android_3: 'Abre "Permisos de ubicación de la aplicación"',
  gps_location_android_4: 'Desplázate hasta el final en la sección "No permitidas" y verifica si',
  gps_location_android_5: 'está ahí',
  gps_location_android_6: 'Si ese es el caso, haz clic sobre ella y selecciona "Preguntar siempre" o "Permitir solo mientras se usa la aplicación"',

  gps_location_ios_1: 'Ve a "Privacidad y seguridad"',
  gps_location_ios_2: 'Verifica que "Servicios de ubicación" esté activado',
  gps_location_ios_3: 'Vuelve a la configuración del teléfono',
  gps_location_ios_4: 'Desplázate hacia abajo y busca la configuración de',
  gps_location_ios_5: 'Dentro de la configuración, busca "Ubicación"',
  gps_location_ios_6: 'Selecciona "Preguntar" o "Permitir"',

  gps_location_chrome_1: 'Abre Chrome',
  gps_location_chrome_2: 'Navega hasta',
  gps_location_chrome_3: 'Abre la configuración del sitio haciendo clic en el icono de los controles deslizantes situado justo a la izquierda de la dirección del sitio web',
  gps_location_chrome_4: 'Selecciona "Permisos"',
  gps_location_chrome_5: 'Verifica que se permita la "Ubicación"',

  gps_location_safari_1: 'Abre Safari',
  gps_location_safari_2: 'Navega hasta',
  gps_location_safari_3: 'Abre la configuración del sitio haciendo clic en el icono "Aa" situado justo a la izquierda de la dirección del sitio web',
  gps_location_safari_4: 'Selecciona "Configuración del sitio web"',
  gps_location_safari_5: 'En la sección "Permitir", verifica que la "Ubicación" esté configurada en "Preguntar" o "Permitir"',
  gps_location_safari_6: 'Haz clic en "Listo"',

  gps_location_browser_1: 'Abre tu navegador preferido',
  gps_location_browser_2: 'Navega hasta',
  gps_location_browser_3: 'Abre la configuración del sitio web',
  gps_location_browser_4: 'Verifica que el permiso de acceso a la ubicación esté configurado en "Preguntar" o "Permitir"',

  go_without_gps: 'Continúa sin ubicación',
  go_without_gps_warning: 'Solo podrás reproducir contenido ingresando los códigos manualmente.',

  read_instructions: 'Lea las instrucciones',

  go_home: 'Home page',
};

export default es;
