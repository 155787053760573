import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import GeoXp from '@mezzo-forte/geoxp';
import { useOnInit } from '../hooks/useOnInit';
import {
  EMPTY_GEOXP_CONFIG, configHasGeoSpots, configHasManualSpots, getPositionsFromConfig,
} from '../utils/geoXp';

const ExperienceContext = React.createContext(null);

export default function ExperienceProvider({ children }) {
  const init = useOnInit();

  const [config, setConfig] = useState();
  const [positions, setPositions] = useState([]);
  const [active, setActive] = useState([]);
  const [visited, setVisited] = useState([]);
  const [location, setLocation] = useState();
  const [volume, setVolume] = useState(1);
  const [mute, setMute] = useState(false);
  const [started, setStarted] = useState(false);

  // singleton geoXp instance
  const geoXpInstance = useMemo(
    () => new GeoXp(EMPTY_GEOXP_CONFIG),
    [],
  );

  // configuration
  const updatePositions = useCallback((geoConfig) => {
    if (!geoConfig) return;
    setPositions(getPositionsFromConfig(geoConfig));
  }, []);

  const loadConfig = useCallback((data) => {
    if (!data) return;

    setConfig(data);
    updatePositions(data.geo);
  }, [updatePositions]);

  const initConfig = useCallback(() => {
    if (!geoXpInstance) return;
    if (!config) return;

    // reload geoxp config
    geoXpInstance.reload(config);
  }, [geoXpInstance, config]);

  const hasManualSpots = useMemo(
    () => configHasManualSpots(config),
    [config],
  );

  const hasGeoSpots = useMemo(
    () => configHasGeoSpots(config),
    [config],
  );

  const isActive = useMemo(() => active && active.length > 0, [active]);

  const canReplay = useMemo(() => visited && visited.length > 0, [visited]);

  // runtime
  const destroy = useCallback(() => {
    if (!geoXpInstance) return;

    setStarted(false);

    geoXpInstance.clearCookies();
    geoXpInstance.destroy();

    console.info('GeoXp stopped');
  }, [geoXpInstance]);

  const unlock = useCallback(() => {
    if (!geoXpInstance) return;

    geoXpInstance.unlock();
  }, [geoXpInstance]);

  const start = useCallback(() => {
    if (!geoXpInstance) return;
    if (!config) return;

    initConfig();
    unlock();

    setStarted(true);
  }, [config, geoXpInstance, initConfig, unlock]);

  const playManually = useCallback((code) => {
    if (!geoXpInstance) return;

    let id = null;
    const patterns = config?.experience?.patterns || [];
    patterns.forEach((p) => {
      const found = p.spots?.find((s) => s.code?.toUpperCase() === code.toUpperCase());
      if (found) id = found.id;
    });
    if (!id) return 'spot not found';

    return geoXpInstance.forceSpot(id);
  }, [config, geoXpInstance]);

  const stopAll = useCallback(() => {
    if (!geoXpInstance) return;

    if (!active || active.length === 0) return;
    active.forEach((spot) => spot.audio?.stop());
  }, [geoXpInstance, active]);

  const replay = useCallback(() => {
    if (!geoXpInstance) return;

    if (!visited || visited.length === 0) return;
    if (isActive) return;
    geoXpInstance.replaySpot();
  }, [geoXpInstance, visited, isActive]);

  const simulatePosition = useCallback((index) => {
    if (!config) return;
    if (!geoXpInstance) return;

    const position = config.geo?.positions[index];
    if (!position) {
      console.warn('position not found', index);
      return;
    }

    console.info('simulating position', position);

    geoXpInstance.updateGeolocation({
      coords: {
        latitude: position.lat,
        longitude: position.lon,
        accuracy: 10,
      },
    });
  }, [config, geoXpInstance]);
  window.simulatePosition = simulatePosition;

  // add event handlers on init
  useEffect(() => {
    if (!init || !geoXpInstance) return;

    window.geoXp = geoXpInstance;

    // geoXp events subscription
    geoXpInstance.on('active', (spotData) => {
      console.info('[GEOXP EVENT] - Spot active', spotData.id, spotData.label);
    });

    geoXpInstance.on('outgoing', (spotData) => {
      console.info('[GEOXP EVENT] - Spot outgoing', spotData.id, spotData.label);
    });

    geoXpInstance.on('visited', (spotData) => {
      console.info('[GEOXP EVENT] - Spot visited', spotData.id, spotData.label);

      setVisited((prev) => {
        const prevIds = prev?.map((spot) => spot.id);
        return !prevIds.includes(spotData.id)
          ? [...prev, spotData]
          : prev;
      });
    });

    geoXpInstance.on('play', (audioData) => {
      console.info(
        '[GEOXP EVENT] - Play audio',
        audioData.id,
        audioData.spot.id,
        audioData.spot.label,
      );
      setActive((prev) => {
        const prevIds = prev?.map((audio) => audio.id);
        return !prevIds.includes(audioData.id)
          ? [...prev, audioData]
          : prev;
      });
    });

    geoXpInstance.on('stop', (audioData) => {
      console.info(
        '[GEOXP EVENT] - Stop audio',
        audioData.id,
        audioData.spot.id,
        audioData.spot.label,
      );
      setActive((prevActive) => prevActive?.filter((audio) => audio?.id !== audioData?.id));
    });

    geoXpInstance.on('position', (positionData) => {
      // console.info('[GEOXP EVENT] - New position', positionData);
      setLocation(positionData);
    });

    return () => geoXpInstance.destroy();
  }, [geoXpInstance, init]);

  useEffect(() => {
    if (!geoXpInstance) return;
    geoXpInstance.audio.setVolume(volume);
  }, [volume, geoXpInstance]);

  useEffect(() => {
    if (!geoXpInstance) return;

    if (mute) geoXpInstance.audio.setVolume(0);
    else geoXpInstance.audio.setVolume(volume);
  }, [mute, volume, geoXpInstance]);

  useEffect(() => {
    if (isActive) setVisited([]);
  }, [isActive]);

  const contextValue = useMemo(() => ({
    config,
    hasGeoSpots,
    hasManualSpots,
    positions,
    active,
    isActive,
    location,
    volume,
    mute,
    started,
    canReplay,
    visited,
    loadConfig,
    setVolume,
    setMute,
    unlock,
    destroy,
    start,
    playManually,
    stopAll,
    replay,
    setVisited,
  }), [
    config,
    hasGeoSpots,
    hasManualSpots,
    positions,
    active,
    isActive,
    location,
    volume,
    mute,
    started,
    canReplay,
    visited,
    loadConfig,
    setVolume,
    setMute,
    unlock,
    destroy,
    start,
    playManually,
    stopAll,
    replay,
    setVisited,
  ]);

  return (
    <ExperienceContext.Provider value={contextValue}>
      {children}
    </ExperienceContext.Provider>
  );
}

export const useExperience = () => React.useContext(ExperienceContext);
