import React, { useState } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import AdminControls from './AdminControls';
import logo from '../../assets/img/agami_logo_simple_accent.png';
import { ADMIN_PANEL_AFTER } from '../../config/config';

const toolbar = {
  justifyContent: 'space-between',
  paddingTop: '10px',
  paddingBottom: '10px',
};

export default function Header() {
  const [showAdmin, setShowAdmin] = useState(0);

  const openAdminDialog = () => {
    setShowAdmin((prev) => prev + 1);
    setTimeout(() => {
      setShowAdmin((prev) => (prev < ADMIN_PANEL_AFTER ? 0 : prev));
    }, ADMIN_PANEL_AFTER * 1000);
  };

  return (
    <AppBar position="static" color="transparent">
      <Toolbar style={toolbar}>
        <div onClick={openAdminDialog} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <img alt="Agami Route" src={logo} height="50px" />
        </div>
      </Toolbar>
      <AdminControls show={showAdmin >= ADMIN_PANEL_AFTER} onClose={() => setShowAdmin(0)} />
    </AppBar>
  );
}
